<template>
  <div>
    <div
      class="imagePopupSlider"
      v-if="images.length"
      @click="closePopupSlider"
    >
      <div class="imagePopupSlider__conatiner">
        <VueSlickCarousel
          v-bind="slickSettings"
          ref="carousel"
          @afterChange="afterChangeCarousel"
        >
          <VuePerfectScrollbar
            class="imagePopupSlider__content"
            :settings="scrollSettings"
            v-for="(image, index) in images"
            :key="index"
          >
            <div class="imagePopupSlider__image">
              <img class="imagePopupSlider--img" :src="image" />
            </div>
          </VuePerfectScrollbar>
          <template #prevArrow="arrowOption">
            <div class="arrow-left"></div>
          </template>
          <template #nextArrow="arrowOption">
            <div class="arrow-right"></div>
          </template>
        </VueSlickCarousel>
        <div class="imagePopupSlider__nav">
          <span class="currentSlide">01</span> -
          <span class="quantitySlide">32</span>
        </div>
        <a class="imagePopupSlider--close" @click="closePopupSlider"
          ><span class="icon-close"></span
        ></a>
      </div>
    </div>
  </div>
</template>

<style lang="sass">
@import '@/assets/sass/ImagesPopupSlider'
</style>

<script>
import VuePerfectScrollbar from "vue-perfect-scrollbar";
import VueSlickCarousel from "vue-slick-carousel";
import "vue-slick-carousel/dist/vue-slick-carousel.css";
import "vue-slick-carousel/dist/vue-slick-carousel-theme.css";

export default {
  data: () => ({
    images: "",
    imageHeight: 0,
    topPosition: 0,
    index: 0,
    scrollSettings: {
      suppressScrollX: true,
    },
    slickSettings: {
      lazyLoad: "ondemand",
      dots: false,
      arrows: true,
      infinite: true,
      fade: true,
    },
  }),
  methods: {
    openPopupSlider: function (images, index) {
      this.topPosition = document.documentElement.scrollTop;
      document.body.style.overflow = "hidden";
      document.body.style.position = "fixed";
      document.body.style.width = "100%";
      document.body.style.left = "0";
      document.body.style.top = -this.topPosition + "px";
      document.querySelector("html").style.overflow = "hidden";
      document.querySelector("html").style.position = "fixed";
      document.querySelector("html").style.width = "100%";
      document.querySelector("html").style.left = "0";

      this.images = images;
      this.index = index;
      this.image = images[index];
      console.log(index);
      var comp = this;
      setTimeout(function () {
        comp.$refs.carousel.goTo(index);

        var quantityEl = comp.$el.getElementsByClassName("quantitySlide")[0];
        quantityEl.innerText = comp.images.length;

        var currentEl = comp.$el.getElementsByClassName("currentSlide")[0];
        currentEl.innerText = index + 1;
      });
    },
    closePopupSlider: function (e) {
      var comp = this;

      if (
        !(
          e.target.classList.contains("imagePopupSlider__content") ||
          e.target.closest(".imagePopupSlider__content")
        ) &&
        !(
          e.target.classList.contains("arrow-left") ||
          e.target.closest(".arrow-left")
        ) &&
        !(
          e.target.classList.contains("arrow-right") ||
          e.target.closest(".arrow-right")
        ) &&
        !(
          e.target.classList.contains("imagePopupSlider--img") ||
          e.target.closest(".imagePopupSlider--img")
        ) &&
        !(
          e.target.classList.contains("imagePopupSlider__nav") ||
          e.target.closest(".imagePopupSlider__nav")
        )
      ) {
        this.images = "";

        setTimeout(function () {
          document.body.style.overflow = "";
          document.body.style.position = "";
          document.body.style.width = "";
          document.body.style.left = "";
          document.body.style.top = "";
          document.querySelector("html").style.overflow = "";
          document.querySelector("html").style.position = "";
          document.querySelector("html").style.width = "";
          document.querySelector("html").style.left = "";
          setTimeout(window.scrollTo(0, comp.topPosition), 1);
        }, 50);
      }
    },
    afterChangeCarousel: function (slideIndex) {
      var currentEl = this.$el.getElementsByClassName("currentSlide")[0];
      currentEl.innerText = slideIndex + 1;
    },
  },
  components: {
    VuePerfectScrollbar,
    VueSlickCarousel,
  },
};
</script>